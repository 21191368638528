import React, { useEffect } from 'react';
import AdsTruck from '../StrongImages/Truck/AdsTruck.png';
import Bgi from '../StrongImages/Truck/Bgi.jpg'

const ParallaxTruck = () => {
    useEffect(() => {
        const handleScroll = () => {
            const truck = document.getElementById('truck');
            const scrollPosition = window.scrollY;
            let truckSpeed;
            
            // Adjust speed based on viewport width
            if (window.innerWidth >= 768) {
                // Desktop view
                truckSpeed = 1.0; // Adjust this value for desktop
            } else {
                // Mobile view
                truckSpeed = 0.2; // Adjust this value for mobile
            }

            truck.style.transform = `translateX(-${scrollPosition * truckSpeed}px)`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="w-full flex justify-end" style={{ backgroundImage: `url(${Bgi})`,  backgroundPosition: 'center' }}>
            <img className="w-80" id="truck" src={AdsTruck} alt="Truck" />
        </div>
    );
};

export default ParallaxTruck;
