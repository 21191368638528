import React from 'react';
// import { Link } from 'react-router-dom';
import Navbar from '../StrongComponents/Navbar';
import FeatureCard from '../StrongComponents/FeatureCardComponent';
import ServiceCard from '../StrongComponents/ServiceCard';
// import Gallery from '../StrongComponents/Gallery';
import TestimonialSlider from '../StrongComponents/TestimonialSlider';
import YouTube from 'react-youtube'; // Import react-youtube
// import Key1 from '../StrongImages/KeyFeaturesImages/key1.png';
import Key2 from '../StrongImages/KeyFeaturesImages/Key2.png';
import Key3 from '../StrongImages/KeyFeaturesImages/Key3.png';
import Sticker1 from '../StrongImages/OurServices/Sticker1.png';
import Sticker21 from '../StrongImages/OurServices/Sticker21.png';
import Sticker3 from '../StrongImages/OurServices/Sticker3.png';
import Footer from '../StrongComponents/Footer';
import { Helmet } from 'react-helmet';

// import Image1a from '../StrongImages/Gallery/image1a.png'
// import Image2a from '../StrongImages/Gallery/image2a.png'
// import Image3a from '../StrongImages/Gallery/image3a.png'

const AboutPage = () => {
    // Example data for components
    const features = [
        // { icon: Key1 , title: 'Fast Delivery', description: 'Get your order delivered within 2-3 business days.' },
        { icon: Key2, title: 'Materiais de qualidade', description: 'Utilizamos materiais de alta qualidade em nossos produtos.' },
        { icon: Key3, title: '24/7 Apoiar', description: 'Nossa equipe de suporte está disponível 24 horas por dia para ajudá-lo.' },
    ];

    const services = [
        { icon: Sticker1, title: 'Design personalizado', description: 'Crie designs personalizados para seus adesivos e impressões em tela.' },
        { icon: Sticker21, title: 'Envio para todo o planeta', description: 'Enviamos nossos produtos para clientes em todo o mundo.' },
        { icon: Sticker3, title: 'Pagamento seguro', description: 'Faça pagamentos seguros usando gateways de pagamento confiáveis.' },
    ];

   

    const testimonials = [
        { name: 'Alice Smith', message: 'Produtos incríveis e excelente atendimento! Altamente recomendado.' },
        { name: 'Bob Johnson', message: 'Adesivos de ótima qualidade! Definitivamente pedirei novamente.' },
    ];

    // YouTube video ID
    const youtubeVideoId = '5D-4WWMQuKg';

    // YouTube video options
    const opts = {
        playerVars: {
            autoplay: 1,
            controls: 1, // Hide player controls
            modestbranding: 1, // Remove YouTube logo
            loop: 1, // Loop the video
             // Play video inline on mobile
        },
        width: '100%', 
    };

    return (
        <div className="bg-gray-100 text-black dark:bg-black dark:text-white">

            
<Helmet>
    <title>About Us - IGF PRINTS</title>
    <meta name="description" content="Discover IGF PRINTS, our mission, values, and the dedication to quality that drives our printing services." />
    <meta name="keywords" content="about IGF PRINTS, printing mission, printing values, IGF PRINTS story, IGF PRINTS team" />
</Helmet>


            
            
            {/* Navigation bar */}

            <Navbar />

            {/* YouTube video */}
            <YouTube videoId={youtubeVideoId} opts={opts} className=" relative object-contain flex p-4 items-center justify-center  "  />

            {/* About Page Content */}
            <section className="py-20 container mx-auto px-4">
                <h2 className="text-3xl text-center sm:text-4xl font-bold mb-8">Sobre nós</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                    <div className='p-5'>
                        <h3 className="text-xl  font-bold mb-4">Nossa missão</h3>
                        <p className="text-lg text-gray-600 dark:text-gray-300">Na IGF Prints, temos a missão de despertar a imaginação e espalhar alegria através de nossas criações artísticas. Cada adesivo e tela que produzimos são criados com cuidado para ajudá-lo a contar sua história única e tornar extraordinários os momentos comuns. Junte-se a nós enquanto celebramos a individualidade, abraçamos a diversidade e inspiramos positividade, uma criação de cada vez.</p>
                    </div>
                    <div className='p-5'>
                        <h3 className="text-xl font-bold mb-4">Nossa visão</h3>
                        <p className="text-lg text-gray-600 dark:text-gray-300">Na IGF Prints, aspiramos ser o destino preferido para expressão criativa. Estamos comprometidos com a inovação, qualidade e experiências personalizadas em adesivos e impressões em tela. Junte-se a nós enquanto redefinimos a criatividade e capacitamos os indivíduos a compartilhar suas perspectivas únicas com o mundo.</p>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="bg-gray-200 dark:bg-gray-600 py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-8">Key Features</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <FeatureCard key={index} icon={feature.icon} title={feature.title} description={feature.description} />
                        ))}
                    </div>
                </div>
            </section>

            {/* Services Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-8">Our Services</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {services.map((service, index) => (
                            <ServiceCard key={index} icon={service.icon} title={service.title} description={service.description} />
                        ))}
                    </div>
                </div>
            </section>

          

            {/* Testimonials Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-8">Testimonials</h2>
                    <TestimonialSlider testimonials={testimonials} />
                </div>
            </section>

            {/* Footer Section */}
            <footer >
               <Footer/>
            </footer>
        </div>
    );
};

export default AboutPage;
