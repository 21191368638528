const FeatureCard = ({ icon, title, description }) => (
    <div className="feature bg-white dark:bg-gray-200 p-8 rounded-lg shadow">
        <div className="flex items-center justify-center mb-4">
            <img src={icon} alt={title} className="w-12 h-12 mr-4" />
            <h3 className="text-xl text-black font-bold">{title}</h3>
        </div>
        <p className="text-lg text-gray-600">{description}</p>
    </div>
);


export default FeatureCard;
