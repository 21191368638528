import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentIndex, images.length]);

    return (
        <div className="slider-container  ">
            <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className="slider-image     " />
        </div>
    );
};

export default ImageSlider;
