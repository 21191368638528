import React, { useState } from "react";
import im1 from "../StrongImages/GalleryImages/Im1.jpeg";
import im2 from "../StrongImages/GalleryImages/im2.jpeg";
import im3 from "../StrongImages/GalleryImages/im3.jpeg";
import im4 from "../StrongImages/GalleryImages/im4.jpeg";
import im6 from "../StrongImages/GalleryImages/im6.jpeg";
import im7 from "../StrongImages/GalleryImages/im7.jpeg";
import im8 from "../StrongImages/GalleryImages/im8.jpeg";
import im9 from "../StrongImages/GalleryImages/im9.jpeg";
import im10 from "../StrongImages/GalleryImages/im10.jpeg";
import im11 from "../StrongImages/GalleryImages/im11.jpeg";
import im12 from "../StrongImages/GalleryImages/im12.jpeg";
import im13 from "../StrongImages/GalleryImages/im13.jpeg";
import im14 from "../StrongImages/GalleryImages/im14.jpeg";
import im15 from "../StrongImages/GalleryImages/im15.jpeg";
import im16 from "../StrongImages/GalleryImages/im16.jpeg";
import im17 from "../StrongImages/GalleryImages/im17.jpeg";
import im18 from "../StrongImages/GalleryImages/im18.jpeg";
import im19 from "../StrongImages/GalleryImages/im19.jpeg";
import im20 from "../StrongImages/GalleryImages/im20.jpeg";
import im21 from "../StrongImages/GalleryImages/im21.jpeg";
import im22 from "../StrongImages/GalleryImages/im22.jpeg";
import im23 from "../StrongImages/GalleryImages/im23.jpeg";
import im24 from "../StrongImages/GalleryImages/im24.jpeg";
import im25 from "../StrongImages/GalleryImages/im25.jpeg";
import Navbar from "../StrongComponents/Navbar";
import ParallaxTruck from "../StrongComponents/ParallaxTruck";
import Footer from "../StrongComponents/Footer";
import { Helmet } from "react-helmet";

const GalleryPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    im1,
    im2,
    im3,
    im4,
    im6,
    im7,
    im8,
    im9,
    im10,
    im11,
    im12,
    im13,
    im14,
    im15,
    im16,
    im17,
    im18,
    im19,
    im20,
    im21,
    im22,
    im23,
    im24,
    im25,
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
<>
<div>

<Helmet>
    <title>Gallery - IGF PRINTS</title>
    <meta name="description" content="Explore our gallery showcasing a wide range of high-quality prints and creative projects by IGF PRINTS." />
    <meta name="keywords" content="IGF PRINTS gallery, print examples, creative projects, high-quality prints, printing showcase" />
</Helmet>

<Navbar/>
</div>
<div className=" dark:bg-gray-900">

<div className=" bg-transparent dark:bg-gray-900 dark:text-white text-5xl   items-center flex justify-center w-full h-40">
<h1 className="p-4 text-center">
Bem vindo as fotos
</h1>
</div>
<div>
    <ParallaxTruck/>
</div>
    <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 dark:bg-gray-800 gap-4 m-3 ">
      {images.map((image, index) => (
          <div key={index} className="relative overflow-hidden rounded">
          <img
            src={image}
            alt={`Imagee ${index + 1}`}
            className="cursor-pointer transition duration-300 transform hover:scale-105"
            onClick={() => handleImageClick(image)}
            style={{ maxHeight: "300px", width: "100%", objectFit: "cover" }}
            />
        </div>
      ))}

      {/* Modal for displaying full-size image */}
      {selectedImage && (
          <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={handleCloseModal}
          >
          <div
            className="max-w-3xl border  mx-auto p-4 rounded-lg relative"
            onClick={(e) => e.stopPropagation()}
            >
            <img
              className="max-h-full w-full"
              src={selectedImage}
              alt="Full size"
              style={{ maxHeight: "calc(100vh - 100px)", width: "auto" }}
              />
            <button
              className="absolute top-2 right-2 text-white text-lg focus:outline-none"
              onClick={handleCloseModal}
              >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>





    <footer >

               <Footer/> 
            </footer>
      </div>



      </>
  );
};

export default GalleryPage;
