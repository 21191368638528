import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../StrongImages/Logo1.png'; // Import your logo file
import { useToggleTheme } from './ToggleThemeProvider';
import { IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const {setDarkTheme,darkTheme} = useToggleTheme()

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };




    return (
        <nav   className=" shadow-lg  dark:bg-black">
            <div className="container  py-4 px-4 flex justify-between items-center">
                {/* Logo */}
                <Link to="/" className="flex  items-center">
                    <img src={logo1} alt="Sticker & Canvas Logo" className=" h-20 w-auto mr-2" /> {/* Adjust the height and width as needed */}
                </Link>
                {/* Desktop Menu (hidden on mobile) */}
                <div className="hidden md:flex items-center space-x-4 text-gray-900 dark:text-white">
                    <Link to="/" className=" hover:text-gray-400 dark:hover:text-gray-300  font-medium">INÍCIO</Link>
                    <Link to="/gallery" className=" hover:text-gray-400 dark:hover:text-gray-300 font-medium">FOTOS</Link>
                    {/* <Link to="/canvas" className=" hover:text-gray-400 dark:hover:text-gray-300 font-medium">LONA</Link> */}
                    <Link to="/contact" className=" hover:text-gray-400 dark:hover:text-gray-300 font-medium">CONTATO</Link>
                    <Link to="/about" className=" hover:text-gray-400 dark:hover:text-gray-300 font-medium">EMPRESA</Link>
                </div>
                {/* Dark Mode Toggle */}
                
                <IconButton sx={{ ml: 1 }} 
                onClick={()=>{
                    setDarkTheme(darkTheme =="yes"?"no":"yes")
                }}
                 color="inherit">
            {darkTheme =="yes" ? <Brightness4Icon className=' text-gray-200' /> : <Brightness7Icon />}
        </IconButton>
                {/* Mobile Menu Icon */}
                <div className="md:hidden ">
                    <button onClick={toggleMobileMenu} className="text-gray-600 hover:text-gray-800 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>
            </div>
            {/* Mobile Menu (hidden by default) */}
            {mobileMenuOpen && (
                <div className="md:hidden dark:bg-black">
                    <ul className="py-2 px-4">
                        <li><Link to="/" className="block text-black dark:text-white font-medium py-2">INÍCIO</Link></li>
                        <li><Link to="/gallery" className="block text-black dark:text-white font-medium py-2">FOTOS</Link></li>
                        {/* <li><Link to="/canvas" className="block text-black dark:text-white font-medium py-2">LONA</Link></li> */}
                        <li><Link to="/contact" className="block text-black dark:text-white font-medium py-2">CONTATO</Link></li>
                        <li><Link to="/about" className="block text-black dark:text-white font-medium py-2">EMPRESA</Link></li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
