import React, { useEffect, useState } from 'react'

const ToggleThemeContext = React.createContext(null)
const ToggleThemeProvider = ({children}) => {
  const colorModeLocalStore = localStorage.getItem('colorMode') || 'no'
    const [darkTheme,setDarkTheme] = useState(colorModeLocalStore)

    useEffect(()=>{
      localStorage.setItem('colorMode',darkTheme)
      console.log('snnns')
  },[darkTheme])
  return (
    <ToggleThemeContext.Provider
     value={{darkTheme,setDarkTheme}}
    >
        <div className={darkTheme === "yes"?"dark":""}>
            {children}
        </div>
    </ToggleThemeContext.Provider>
  )
}

export default ToggleThemeProvider
export const useToggleTheme =()=> React.useContext(ToggleThemeContext);