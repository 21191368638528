import React from 'react'

export default function Footer() {
  return (
    <div>
       <div className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-400">&copy; 2023 IGF PRINTS. All rights reserved.</p>
                </div>
            </div>
    </div>
  )
}
