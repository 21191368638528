import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './StrongScreens/HomePage';
import GalleryPage from './StrongScreens/GalleryPage';
import ContactPage from './StrongScreens/ContactPage';
import AboutPage from './StrongScreens/AboutPage'; // Import the AboutPage component
import NotFoundPage from './StrongScreens/NotFoundPage'; // Assuming this is your 404 page
import ToggleThemeProvider from './StrongComponents/ToggleThemeProvider';

const App = () => {
    return (
        <ToggleThemeProvider>

            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} /> {/* Add the AboutPage route */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>

        </ToggleThemeProvider>
    );
};

export default App;
