const ServiceCard = ({ icon, title, description }) => (
    <div className="service bg-white dark:bg-gray-600 p-9 rounded-lg shadow">
        <div className="flex items-center object-contain justify-center mb-4">
            <img src={icon} alt={title} className="  w-auto h-20 mr-4" />
            <h3 className="text-xl text-center  font-bold">{title}</h3>
        </div>
        <p className="text-lg text-black dark:text-white">{description}</p>
    </div>
);



export default ServiceCard;
