import React, { useState } from 'react';
import Navbar from '../StrongComponents/Navbar';
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { Button, Grid, Snackbar } from "@mui/material";
import Youtubelogo from '../StrongImages/SocialMediaLogos/YoutubeLogo.png';
import Facebooklogo from '../StrongImages/SocialMediaLogos/FacebookLogo.png';
import Instagramlogo from '../StrongImages/SocialMediaLogos/InstagramLogo.png';
import Footer from '../StrongComponents/Footer';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const saveData = () => {
        console.log(JSON.stringify({
            name: name,
            email: email,
            phone: phone,
            message: message,
        }));

        // Perform validation for email and phone number
        const phoneRegex = /^[0-9]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!phone.match(phoneRegex)) {
            alert("Please enter a valid phone number.");
            return;
        }

        if (!email.match(emailRegex)) {
            alert("Please enter a valid email address.");
            return;
        }

        // Data sent successfully, clear form and show notification
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setOpenSnackbar(true);
        
        // Fetch request to send data to Google Sheets
        fetch(
            "https://script.google.com/macros/s/AKfycbyiSTJtkS78aUUHuuez5ThIx2Ml_cmh14hsv_dwA94QGP-x4UsjOzdXwlgnkyGiOc5Y/exec",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "no-cors",
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                }),
            }
        )
        .then((response) => response.text())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    };

    return ( 
        <div className="bg-gray-100 text-black dark:text-white">
            {/* Navigation bar */}


            <Helmet>
    <title>Contact Us - IGF PRINTS</title>
    <meta name="description" content="Get in touch with IGF PRINTS for all your printing needs. Reach out to our team for inquiries, quotes, and support." />
    <meta name="keywords" content="contact IGF PRINTS,printing inquiries,printing quotes,customer support,IGF PRINTS contact information" />
</Helmet>


            <Navbar />

            {/* Contact Form Section */}
            <section className="py-20 dark:bg-gray-300 ">
                <div className="container mx-auto  ">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-8 dark:text-black text-center">Contate-nos</h1>
                    <Grid container spacing={3} className='p-5  '>
                        <Grid item xs={12} sm={6} className=''>
                            <TextField className=' '
                                value={name}
                                fullWidth
                                label="Name"
                                variant="filled" 
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={email}
                                fullWidth
                                label="Email"
                                variant="filled"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={phone}
                                fullWidth
                                label="Phone"
                                variant="filled"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={message}
                                fullWidth
                                label="Message"
                                multiline
                                minRows={5}
                                variant="filled"
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                endIcon={<SendIcon />}
                                onClick={saveData}
                            >
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message="Message sent successfully!"
            />

         {/* Contact Information Section */}
<section className="bg-gray-200 dark:bg-gray-900 py-20">
    <div className="container mx-auto px-4">
        <h2 className="text-2xl sm:text-3xl font-bold mb-8">Informações de contato</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>
                <h3 className="text-xl font-semibold mb-2">Endereço</h3>
                <p>Rua Presidente Prudente 1170 loja 2</p>
            </div>
            <div>
                <h3 className="text-xl font-semibold mb-2">Email</h3>
                <p>igfprintperuibe@gmail.com</p>
            </div>
            <div>
                <h3 className="text-xl font-semibold mb-2">Phone</h3>
                <p> (13) 97816-8583</p>
            </div>
            {/* Social media logos */}
            <div className="flex items-center">
    {/* YouTube logo with link */}
    <a href="https://www.youtube.com/@IGFPRINT" target="_blank" rel="noopener noreferrer" className="transition duration-300 ease-in-out transform hover:scale-110">
        <img  src={Youtubelogo} alt="YouTube" className="w-10 h-10 mr-4 hover:shadow-md" />
    </a>
    {/* Instagram logo with link */}
    <a href="https://www.instagram.com/igfprint/" target="_blank" rel="noopener noreferrer" className="transition duration-300 ease-in-out transform hover:scale-110">
        <img  src={Instagramlogo} alt="Instagram" className="w-8 h-8 mr-4 hover:shadow-md" />
    </a>
    {/* Facebook logo with link */}
    <a href="https://www.facebook.com/profile.php?id=61559416551361&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="transition duration-300 ease-in-out transform hover:scale-110">
        <img  src={Facebooklogo} alt="Facebook" className="w-8 h-8 hover:shadow-md" />
    </a>
</div>

        </div>
    </div>
</section>


            {/* Map Section (Optional) */}
            <section className="py-20">
                <div className=' text-center  dark:text-black font-extrabold text-lg p-4'>
                    <h1>
                         IGF PRINTS
                    </h1>
                </div>
    <div className="relative" style={{ paddingTop: "56.25%" }}>
        
        <iframe
            title="IGF PRINTS Map"
            className="absolute top-0 left-0 w-full h-full"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=IGF%20PRINTS,%20Rua%20Presidente%20Prudente%20Peru%C3%ADbe%20-%20SP%2011750-000%20Brazil+(IGF%20PRINTS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
    </div>
</section>




            {/* Footer Section */}
            <footer >
               <Footer/>
            </footer>
        </div>
    );
};

export default ContactPage;
