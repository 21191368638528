import React, { useState } from 'react';

const TestimonialSlider = ({ testimonials }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    return (
        <div className="testimonial-slider-container relative max-w-md mx-auto">
            <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 dark:bg-gray-900 dark:text-white text-gray-600 hover:text-gray-800 hover:bg-gray-300 px-3 py-1 rounded-full"
                onClick={prevTestimonial}
            >
                &#10094;
            </button>
            <div className="testimonial-content overflow-hidden">
                <div className="testimonial bg-white dark:bg-gray-400 p-10 rounded-lg shadow ">
                    <p className="text-lg text-gray-800 mb-4 ">{testimonials[currentIndex].message}</p>
                    <p className="text-gray-600">- {testimonials[currentIndex].name}</p>
                </div>
            </div>
            <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 dark:bg-gray-900 dark:text-white text-gray-600 hover:text-gray-800 hover:bg-gray-300 px-3 py-1 rounded-full"
                onClick={nextTestimonial}
            >
                &#10095;
            </button>
        </div>
    );
};

export default TestimonialSlider;
